import axios from '@axios'

export default {
  namespaced: true,
  state: {
    merchants: [],
  },
  getters: {},
  mutations: {},
  actions: {
    // 获取数据
    fetchMerchants() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取下拉列表的merchant数据
    fetchDropDownMerchants() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/dropDownList')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取payout的merchant数据
    fetchPayoutMerchants() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/payout-merchants')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 根据NO获取
    fetchMerchantByNo(ctx, merchantNo) {
      const params = {
        merchant_no: merchantNo,
      }
      return new Promise((resolve, reject) => {
        axios.post('/merchant/list', params)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取APP数据
    fetchMerchantApps(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/list?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取下拉列表的APP数据
    fetchDropDownAppsMerchant(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/dropDownList?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取渠道列表筛选信息
    fetchChannelFilters() {
      return new Promise((resolve, reject) => {
        axios.post('/channel/listChannelFilters')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 生成默认渠道数据
    fetchPayChannelListDetail() {
      return new Promise((resolve, reject) => {
        axios.post('/channel/listAllSubChannelsDetail')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取订单数据
    fetchTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/list?page_num=${params.page_num}&page_size=10&date_mode=${params.date_mode}&search_mode=${params.search_mode}&search_key=${params.search_key}`, params)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 下载订单请求
    downloadTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/download?date_mode=${params.date_mode}&data_filter=${params.data_filter}&search_mode=${params.search_mode}&search_key=${params.search_key}&zone_id=${params.zone_id}`, params)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取订单下载记录
    fetchDownloadList() {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/download-list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 创建商户
    createMerchant(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/create', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新商户
    updateMerchant(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/update', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取商户配置信息
    fetchMerchantConfigs(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/configs/${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Merchant Fee Config Section
    // 获取数据
    fetchMerchantFeeConfigs(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/fee/list?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新商户费用配置
    saveMerchantFeeConfigs(ctx, configs) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/fee/save', configs)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 新增静态账户号段
    submitTransferAccountRangeConfigs(ctx, configs) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/transfer/range/allocate', configs)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTransferAccountRangeConfigs(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/transfer/range/list?merchant_no=${params.merchant_no}&page_num=${params.page_num}&page_size=${params.page_size}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 删除商户费用配置
    removeMerchantFeeConfigs(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/fee/remove', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // V1 Sync
    syncMerchantToV1(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/merchant/sync-merchant?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Merchant Assets
    queryMerchantAssets(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/assets/detail?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Merchant Assets Opt Logs
    fetchMerchantAssetsOptLogs(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/assets/opt-logs?merchant_no=${params.merchantNo}&start=${params.start}&end=${params.end}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取APP配置数据
    fetchAppConfigs(ctx, appId) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/configs/${appId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchAppConfigDetail(ctx, appId) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/configs/list?app_id=${appId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新APP
    updateApp(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/app/update', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Add APP Config
    removeAppConfig(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.delete('/merchant/app/configs/remove', { data: item })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Remove APP Config
    addAppConfig(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.put('/merchant/app/configs/add', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // APP channel
    fetchAppChannels(ctx, appId) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/channel/list?app_id=${appId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchAppSupportedChannels(ctx, appId) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/channel/listSupportedSubChannels?app_id=${appId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    listGlobalSubChannels(ctx, appId) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/channel/listGlobalSubChannels?app_id=${appId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateAppChannel(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/channel/update?app_id=${item.key_app_id}&region=${item.key_region}&method=${item.key_method}&channel=${item.key_channel}`, item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addAppChannel(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.put('/merchant/app/channel/add', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteAppChannel(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.delete('/merchant/app/channel/delete', { data: item })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // APP channel fees
    fetchAppChannelFees(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/app/channel/fee/list', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateAppChannelFee(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/app/channel/fee/update', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteAppChannelFee(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.delete('/merchant/app/channel/fee/delete', { data: params })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 导出商户费率
    exportMerchantFees() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/export-merchant-fees', null, { responseType: 'blob' })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取数据
    fetchApps() {
      return new Promise((resolve, reject) => {
        axios.post('/app/list-all')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新APP虚拟网址
    updateMockWebsiteUrl(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/modify-mock-website', params)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 更新APP虚拟名称
    updateMockAppName(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/modify-mock-app-name', params)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取Sanctions数据
    fetchSanctions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/sanction/query?doc_type=${params.type}&key=${params.searchKey}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取Sanctions数据
    copyAppChannelConfig(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/copy-configs?from=${params.from}&to=${params.to}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // list config keys
    listRiskConfigKeys() {
      return new Promise((resolve, reject) => {
        axios.post('/common/rc-keys')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // fetch APP Risk Controll Config
    fetchAppRcConfigList(ctx, param) {
      return new Promise((resolve, reject) => {
        axios.post(`/app/rcc/list?app_id=${param}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // add APP Risk Controll Config
    addAppRcConfig(ctx, param) {
      return new Promise((resolve, reject) => {
        axios.put('/app/rcc/add', param)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // del APP Risk Controll Config
    removeAppRcConfig(ctx, param) {
      return new Promise((resolve, reject) => {
        axios.delete('/app/rcc/remove', { data: param })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    modifyAssetDataManually(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/assets/data/modify?verification_code=${params.verificationCode}`, params.assets)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // tongdun permission
    getTongdunToken() {
      return new Promise((resolve, reject) => {
        axios.post('/tongdun/token')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchMerchantCountry() {
      return new Promise((resolve, reject) => {
        axios.post('/channel/listChannelElements')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchKycJobcount() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/kyc/jobcount')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchKycList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/kyc/list?search=${params.id}&page_num=${params.page_num}&page_size=${params.page_size}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchKycDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/kyc/getKYCReviewInfo?merchant_no=${params.merchant_no}&id=${params.id}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    uploadKycFile(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/kyc/uploadfiles', params, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    submitKycDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/kyc/submit?merchant_no=${params.id}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCountryAndRegions() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/kyc/getCountryAndRegions')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getRegions() {
      return new Promise((resolve, reject) => {
        axios.post('/common/regions')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    downloadKYCDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/kyc/download?merchant_no=${params.merchant_no}&id=${params.id}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveAppPics(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/kyc/saveAppPics?app_no=${params.id}`, params.list)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTraining(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`aml/train/list?page_num=${params.page_num}&page_size=${params.page_size}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveTraining(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('aml/train/save', params, {
          headers: {
            'content-type': 'application/json',
          },
        })
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    uploadTraining(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('aml/train/uploadfiles', params)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    kycReturnData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/kyc/returnData?merchant_no=${params.id}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchMerchantUsers(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/users/list?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    resetMerchantUserPassword(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/users/reset-password?merchant_uid=${uid}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    filterAppList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/app/list?access_token=${params.access_token}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    filterAppPage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/app/page?access_token=${params.access_token}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    withdrawByAdmin(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/assets/withdraw?merchant_no=${params.merchant_no}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Payout Account Related
    addPayoutAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.put('/merchant/payout-account/create', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updatePayoutAccount(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/payout-account/update', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deletePayoutAccount(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/merchant/payout-account/delete-by-id?id=${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    findPayoutAccountById(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/payout-account/query-by-id?id=${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    listPayoutAccountByMerchant(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/payout-account/list-by-merchant?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 获取payout汇率
    getPayoutExchangeRate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/assets/withdraw/exchange-rate?merchant_no=${params.merchantNo}&source=${params.source}&target=${params.target}&payout_id=${params.payout_id}&amount=${params.amount}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // Forter Merchant Creation
    forterSiteCreation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/create-forter-merchant?merchant_no=${params.merchantNo}&unique_name=${params.siteName}&mcc=${params.mcc}`)
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // ALL Provider list
    fetchDropProviders() {
      return new Promise((resolve, reject) => {
        axios.post('/common/dropDownProviders')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    downloadApps(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/download', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchAppsDownloadList() {
      return new Promise((resolve, reject) => {
        axios.post('/app/download-list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
