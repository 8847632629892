<template>

  <div>
    <!-- Add New Sidebar -->
    <merchant-fee-config-add-new
      :is-merchant-fee-config-sidebar-active.sync="isMerchantFeeConfigSidebarActive"
      :data="merchantFeeConfigInfo"
      @updated="queryConfigs"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              :to="{ name: 'merchant-list' }"
              class="mr-2"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              <span class="align-middle">Back</span>
            </b-button>
            <b-button
              variant="outline-primary"
              @click="addNew"
            >
              <feather-icon
                icon="PlusIcon"
              />
              <span class="align-middle">Add New</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="configs"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <!-- Column: Status -->
        <template #cell(target_type)="data">
          {{ resolveTypeText(data.item.target_type) }}
        </template>

        <!-- Column: Status -->
        <template #cell(target)="data">
          {{ resolveTargetText(data.item.target) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`config-row-${data.index}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="editData(data.item)"
            />

            <feather-icon
              :id="`config-row-${data.index}-delete-icon`"
              icon="Trash2Icon"
              size="16"
              class="cursor-pointer mx-1"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton,
} from 'bootstrap-vue'
import useMerchantsJs from './merchants'
import MerchantFeeConfigAddNew from './MerchantFeeConfigAddNew.vue'

const {
  fetchMerchantFeeConfigs,
  removeMerchantFeeConfigs,
} = useMerchantsJs()

export default {
  components: {
    MerchantFeeConfigAddNew,

    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
  },
  data() {
    return {
      merchantNo: this.$route.query.merchant_no,
      configs: [],
      tableFields: [
        { key: 'target_type', label: 'TYPE' },
        { key: 'target', label: 'TARGET' },
        'actions',
      ],

      isMerchantFeeConfigSidebarActive: false,
      merchantFeeConfigInfo: {},
    }
  },
  watch: {
  },
  created() {
    this.queryConfigs()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    queryConfigs() {
      fetchMerchantFeeConfigs(this, this.merchantNo, configs => {
        this.configs = configs
      })
    },
    deleteConfirm(config) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteData(config)
        }
      })
    },
    deleteData(config) {
      removeMerchantFeeConfigs(
        config,
        () => {
          this.queryConfigs()
          this.showAlert('success', 'Deleted!', 'Configuration information deleted successfully')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    resolveTypeText(type) {
      switch (type) {
        case '1':
          return 'Region'
        case '2':
          return 'APP'
        case '3':
          return 'Currency'
        default:
          return 'Unknown'
      }
    },
    resolveTargetText(target) {
      switch (target) {
        case 'USA':
          return 'United States of America'
        case 'BRA':
          return 'Brazil'
        case 'PHL':
          return 'Philippines'
        case 'CHN':
          return 'China'
        case 'RUS':
          return 'Russian Federation'
        case 'IDN':
          return 'Indonesia'
        case 'MEX':
          return 'Mexico'
        default:
          return target
      }
    },
    addNew() {
      this.merchantFeeConfigInfo = {
        merchantNo: this.merchantNo,
      }
      this.isMerchantFeeConfigSidebarActive = true
    },
    editData(feeDetail) {
      this.merchantFeeConfigInfo = {
        merchantNo: feeDetail.merchant_no,
        feeType: feeDetail.target_type,
        feeRegion: feeDetail.target,
        config_list: feeDetail.config_list,
      }
      this.isMerchantFeeConfigSidebarActive = true
    },
  },
}
</script>
