import store from '@/store'
import merchantStoreModule from '../../merchantStoreModule'
import commonStoreModule from '../../commonStoreModule'
import channelStoreModule from '../../channelStoreModule'

export default function merchantsJs() {
  const MERCHANT_STORE_MODULE_NAME = 'merchant'
  const COMMON_STORE_MODULE_NAME = 'common'
  const CHANEL_STORE_MODULE_NAME = 'channel'

  // Register module
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)
  if (!store.hasModule(COMMON_STORE_MODULE_NAME)) store.registerModule(COMMON_STORE_MODULE_NAME, commonStoreModule)
  if (!store.hasModule(CHANEL_STORE_MODULE_NAME)) store.registerModule(CHANEL_STORE_MODULE_NAME, channelStoreModule)

  const fetchMerchants = (vm, callback) => {
    store
      .dispatch('merchant/fetchMerchants')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Merchants', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchMerchantApps = (vm, merchantNo, callback) => {
    store
      .dispatch('merchant/fetchMerchantApps', merchantNo)
      .then(response => {
        const { code, data } = response.data

        const apps = [{ label: 'All', value: '' }]
        if (code === '10000') {
          data.forEach(item => {
            const app = { label: item.app_name, value: item.app_id }
            apps.push(app)
          })
        }
        callback(apps)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Merchant APPs', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchChannelFilters = (vm, callback) => {
    store
      .dispatch('merchant/fetchChannelFilters')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Channels', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchPayChannelListDetail = (vm, callback) => {
    store
      .dispatch('merchant/fetchPayChannelListDetail')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Channel List Detail', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const create = (params, success, fail) => {
    store
      .dispatch('merchant/createMerchant', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const update = (params, success, fail) => {
    store
      .dispatch('merchant/updateMerchant', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantConfigs = (vm, merchantNo, success) => {
    store
      .dispatch('merchant/fetchMerchantConfigs', merchantNo)
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          success(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching merchant configs', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchMerchantInfo = (vm, params, success) => {
    store
      .dispatch('merchant/fetchMerchantByNo', params)
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          success(data[0])
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching merchant info', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  // Merchant Fee Config Actions
  const fetchMerchantFeeConfigs = (vm, merchantNo, success) => {
    store
      .dispatch('merchant/fetchMerchantFeeConfigs', merchantNo)
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          success(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching merchant fee configs', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const saveMerchantFeeConfigs = (configs, success, fail) => {
    store
      .dispatch('merchant/saveMerchantFeeConfigs', configs)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const submitTransferAccountRangeConfigs = (configs, success, fail) => {
    store
      .dispatch('merchant/submitTransferAccountRangeConfigs', configs)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchTransferAccountRangeConfigs = (vm, param, success) => {
    store
      .dispatch('merchant/fetchTransferAccountRangeConfigs', param)
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          success(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching merchant fee configs', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const removeMerchantFeeConfigs = (params, success, fail) => {
    store
      .dispatch('merchant/removeMerchantFeeConfigs', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const syncMerchantToV1 = (params, success, fail) => {
    store
      .dispatch('merchant/syncMerchantToV1', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const queryMerchantAssets = (params, success, fail) => {
    store
      .dispatch('merchant/queryMerchantAssets', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantAssetsOptLogs = (params, success, fail) => {
    store
      .dispatch('merchant/fetchMerchantAssetsOptLogs', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantAppsDetail = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/fetchMerchantApps', merchantNo)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => fail('error'))
  }

  const fetchAppConfigs = (params, success, fail) => {
    store
      .dispatch('merchant/fetchAppConfigs', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchAppConfigDetail = (params, success, fail) => {
    store
      .dispatch('merchant/fetchAppConfigDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateApp = (params, success, fail) => {
    store
      .dispatch('merchant/updateApp', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const removeAppConfig = (params, success, fail) => {
    store
      .dispatch('merchant/removeAppConfig', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const addAppConfig = (params, success, fail) => {
    store
      .dispatch('merchant/addAppConfig', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchAppChannels = (params, success, fail) => {
    store
      .dispatch('merchant/fetchAppChannels', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchAppSupportedChannels = (params, success, fail) => {
    store
      .dispatch('merchant/fetchAppSupportedChannels', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }
  const listGlobalSubChannels = (params, success, fail) => {
    store
      .dispatch('merchant/listGlobalSubChannels', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateAppChannel = (params, success, fail) => {
    store
      .dispatch('merchant/updateAppChannel', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const addAppChannel = (params, success, fail) => {
    store
      .dispatch('merchant/addAppChannel', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const deleteAppChannel = (params, success, fail) => {
    store
      .dispatch('merchant/deleteAppChannel', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchAppChannelFees = (params, success, fail) => {
    store
      .dispatch('merchant/fetchAppChannelFees', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateAppChannelFee = (params, success, fail) => {
    store
      .dispatch('merchant/updateAppChannelFee', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const deleteAppChannelFee = (params, success, fail) => {
    store
      .dispatch('merchant/deleteAppChannelFee', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const exportMerchantFees = (success, fail) => {
    store
      .dispatch('merchant/exportMerchantFees')
      .then(response => {
        success(response)
      }).catch(() => {
        fail('error')
      })
  }

  const copyAppChannelConfig = (params, success, fail) => {
    store
      .dispatch('merchant/copyAppChannelConfig', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const listRiskConfigKeys = (success, fail) => {
    store
      .dispatch('merchant/listRiskConfigKeys')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchAppRcConfigList = (params, success, fail) => {
    store
      .dispatch('merchant/fetchAppRcConfigList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const addAppRcConfig = (params, success, fail) => {
    store
      .dispatch('merchant/addAppRcConfig', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const removeAppRcConfig = (params, success, fail) => {
    store
      .dispatch('merchant/removeAppRcConfig', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const modifyAssetDataManually = (params, success, fail) => {
    store
      .dispatch('merchant/modifyAssetDataManually', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantCountry = (vm, callback) => {
    store
      .dispatch('merchant/fetchMerchantCountry')
      .then(response => {
        const { code, data } = response.data

        const merchantCountryList = [{ label: 'Unknown', value: '' }]
        if (code === '10000') {
          data.regions.forEach(item => {
            const merchantCountry = { label: item.region_name, value: item.region_code }
            merchantCountryList.push(merchantCountry)
          })
        }

        callback(merchantCountryList)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching country options', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchIndustryOptions = (success, fail) => {
    store
      .dispatch('common/fetchIndustryOptions')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          const options = []
          Object.keys(data).forEach(key => {
            const item = {
              value: data[key].code,
              label: data[key].name,
              excludive: data[key].exclusive,
            }
            options.push(item)
          })
          success(options)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const fetchCommonOptions = (success, fail) => {
    store
      .dispatch('common/fetchCommonOptions')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          store.commit('common/SET_CURRENCIES', data.currencies)
          store.commit('common/SET_COUNTRIES', data.countries)
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const fetchReviewTransactions = (params, success, fail) => {
    store
      .dispatch('aml/fetchReviewTransactions', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchKycJobcount = (success, fail) => {
    store
      .dispatch('merchant/fetchKycJobcount')
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchKycList = (success, fail, params) => {
    store
      .dispatch('merchant/fetchKycList', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const fetchKycDetail = (success, fail, params) => {
    store
      .dispatch('merchant/fetchKycDetail', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  const uploadKycFile = (success, fail, params) => {
    store
      .dispatch('merchant/uploadKycFile', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const submitKycDetail = (success, fail, params) => {
    store
      .dispatch('merchant/submitKycDetail', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const getCountryAndRegions = (success, fail, params) => {
    store
      .dispatch('merchant/getCountryAndRegions', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const getRegions = (success, fail, params) => {
    store
      .dispatch('merchant/getRegions', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const downloadKYCDetail = (success, fail, params) => {
    store
      .dispatch('merchant/downloadKYCDetail', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const saveAppPics = (success, fail, params) => {
    store
      .dispatch('merchant/saveAppPics', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const kycReturnData = (success, fail, params) => {
    store
      .dispatch('merchant/kycReturnData', params)
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchTongdunStrategies = (success, fail) => {
    store
      .dispatch('common/fetchTongdunStrategies')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const fetchMerchantUsers = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/fetchMerchantUsers', merchantNo)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const resetMerchantUserPassword = (uid, success, fail) => {
    store
      .dispatch('merchant/resetMerchantUserPassword', uid)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const addPayoutAccount = (params, success, fail) => {
    store
      .dispatch('merchant/addPayoutAccount', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updatePayoutAccount = (params, success, fail) => {
    store
      .dispatch('merchant/updatePayoutAccount', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const deletePayoutAccount = (params, success, fail) => {
    store
      .dispatch('merchant/deletePayoutAccount', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const findPayoutAccountById = (id, success, fail) => {
    store
      .dispatch('merchant/findPayoutAccountById', id)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const listPayoutAccountByMerchant = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/listPayoutAccountByMerchant', merchantNo)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const forterSiteCreation = (params, success, fail) => {
    store
      .dispatch('merchant/forterSiteCreation', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchChannelGroupByRegionAndMethod = (params, success, fail) => {
    store
      .dispatch('channel/fetchChannelGroupByRegionAndMethod', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  return {
    fetchMerchants,
    fetchMerchantApps,
    fetchChannelFilters,
    fetchPayChannelListDetail,
    create,
    update,
    fetchMerchantConfigs,
    fetchMerchantFeeConfigs,
    saveMerchantFeeConfigs,
    removeMerchantFeeConfigs,
    syncMerchantToV1,
    queryMerchantAssets,
    fetchMerchantAssetsOptLogs,
    fetchMerchantAppsDetail,
    fetchAppConfigs,
    fetchAppConfigDetail,
    updateApp,
    removeAppConfig,
    addAppConfig,
    fetchAppChannels,
    fetchAppSupportedChannels,
    updateAppChannel,
    addAppChannel,
    deleteAppChannel,
    fetchAppChannelFees,
    updateAppChannelFee,
    deleteAppChannelFee,
    exportMerchantFees,
    copyAppChannelConfig,
    listRiskConfigKeys,
    fetchAppRcConfigList,
    addAppRcConfig,
    removeAppRcConfig,
    modifyAssetDataManually,
    fetchMerchantCountry,
    fetchMerchantInfo,
    fetchReviewTransactions,
    fetchIndustryOptions,
    fetchCommonOptions,
    fetchKycJobcount,
    fetchKycList,
    fetchKycDetail,
    uploadKycFile,
    submitKycDetail,
    getCountryAndRegions,
    getRegions,
    downloadKYCDetail,
    saveAppPics,
    kycReturnData,
    fetchTongdunStrategies,
    fetchMerchantUsers,
    resetMerchantUserPassword,
    addPayoutAccount,
    updatePayoutAccount,
    deletePayoutAccount,
    findPayoutAccountById,
    listPayoutAccountByMerchant,
    forterSiteCreation,
    submitTransferAccountRangeConfigs,
    fetchTransferAccountRangeConfigs,
    listGlobalSubChannels,
    fetchChannelGroupByRegionAndMethod,
  }
}
