<template>
  <b-sidebar
    id="merchant-fee-config-sidebar"
    :visible="isMerchantFeeConfigSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-merchant-fee-config-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Region Fee Configuration
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">

          <!-- Merchant NO. -->
          <b-form-group
            label="NO."
            label-for="merchant-no"
          >
            <b-form-input
              id="merchant-no"
              v-model="merchantNo"
              autofocus
              trim
              disabled
            />
          </b-form-group>

          <!-- Type -->
          <b-form-group
            label="Type"
            label-for="fee-type"
          >
            <v-select
              v-model="feeType"
              :options="typeOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="fee-type"
            />
          </b-form-group>

          <div v-if="feeType === '1'">
            <!-- Region -->
            <b-form-group
              label="Region"
              label-for="fee-region"
            >
              <v-select
                v-model="feeRegion"
                :options="regionOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
                input-id="fee-region"
              />
            </b-form-group>

            <!-- Settlement Fee -->
            <validation-provider
              #default="{ errors }"
              name="Settlement Fee"
              rules="required"
            >
              <b-form-group
                label="Settlement Fee"
                label-for="settlement-fee"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-input
                      id="settlement-fee"
                      v-model="settlementFee"
                      autofocus
                      trim
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="settlementFeeCurrency"
                      :options="currencyOptions"
                      :clearable="false"
                      :searchable="false"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </validation-provider>

            <!-- Chargeback Coverage -->
            <validation-provider
              #default="{ errors }"
              name="Chargeback Coverage"
              rules="required"
            >
              <b-form-group
                label="Chargeback Coverage"
                label-for="chargeback-coverage"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-input
                      id="chargeback-coverage"
                      v-model="chargebackCoverage"
                      autofocus
                      trim
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="chargebackCoverageCurrency"
                      :options="currencyOptions"
                      :clearable="false"
                      :searchable="false"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </validation-provider>
          </div>

          <!-- Currency Configurations -->
          <div v-if="feeType === '3'">
            <!-- Currency -->
            <b-form-group
              label="Currency"
              label-for="fee-currency"
            >
              <v-select
                v-model="feeCurrency"
                :options="currencyOptions"
                :clearable="false"
                :searchable="false"
                input-id="fee-currency"
              />
            </b-form-group>

            <!-- Reserve Fund Period -->
            <b-form-group
              label="Reserve Fund Period"
              label-for="reserve-fund-period"
            >
              <b-form-input
                id="reserve-fund-period"
                v-model="reserveFundPeriod"
                autofocus
                trim
              />
            </b-form-group>

            <!-- Reserve Fund Capacity -->
            <b-form-group
              label="Reserve Fund Capacity"
              label-for="reserve-fund-capacity"
            >
              <b-form-input
                id="reserve-fund-capacity"
                v-model="reserveFundCapacity"
                autofocus
                trim
              />
            </b-form-group>
          </div>

          <el-divider class="mt-3 mb-3" />

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!isFormValid"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BForm, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useMerchantsJs from './merchants'

const {
  saveMerchantFeeConfigs,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BRow,
    BCol,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isMerchantFeeConfigSidebarActive',
    event: 'update:is-merchant-fee-config-sidebar-active',
  },
  props: {
    isMerchantFeeConfigSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      merchantNo: '',
      feeType: '1',
      feeRegion: 'BRA',
      chargebackFee: 0,
      chargebackFeeCurrency: 'BRL',
      refundFee: 0,
      refundFeeCurrency: 'BRL',
      taxFee: 0,
      taxFeeCurrency: 'BRL',
      avsFee: 0,
      avsFeeCurrency: 'BRL',
      settlementFee: 0,
      settlementFeeCurrency: 'BRL',
      chargebackCoverage: 0,
      chargebackCoverageCurrency: '%',
      feeCurrency: '',
      reserveFundPeriod: '',
      reserveFundCapacity: '',
      error: '',

      typeOptions: [
        { label: 'Region', value: '1' },
        { label: 'APP', value: '2' },
        { label: 'Currency', value: '3' },
      ],

      regionOptions: [
        { label: 'United States of America', value: 'USA' },
        { label: 'Brazil', value: 'BRA' },
        { label: 'Philippines', value: 'PHL' },
        { label: 'China', value: 'CHN' },
        { label: 'Russian Federation', value: 'RUS' },
        { label: 'Indonesia', value: 'IDN' },
        { label: 'Mexico', value: 'MEX' },
      ],

      currencyOptions: [
        'USD',
        'BRL',
        'MXN',
        'EUR',
        'PHP',
        'CNY',
        'INR',
        'RUB',
        'SAR',
        'AED',
        'KWD',
        'QAR',
        'OMR',
        'CLP',
        'PEN',
        'COP',
        '%',
      ],

      required,
    }
  },
  computed: {
    isFormValid() {
      return this.feeType
    },
  },
  watch: {
    isMerchantFeeConfigSidebarActive(val) {
      if (!val) {
        return
      }
      this.initMerchantInfo(this.data)
    },
  },
  methods: {
    initMerchantInfo(info) {
      this.merchantNo = info.merchantNo
      this.feeType = info.feeType
      this.feeRegion = info.feeRegion
      this.feeCurrency = info.feeRegion
      if (info.config_list && info.config_list.length > 0) {
        info.config_list.forEach(config => {
          if (config.config_key.toUpperCase() === 'SETTLEMENT_FEE') {
            [, , this.settlementFee, this.settlementFeeCurrency] = config.config_value.split(',')
          }
          if (config.config_key.toUpperCase() === 'CHARGEBACK_COVERED') {
            [, , this.chargebackCoverage, this.chargebackCoverageCurrency] = config.config_value.split(',')
          }
          if (config.config_key.toUpperCase() === 'RESERVE_FUND_PERIOD') {
            this.reserveFundPeriod = config.config_value
          }
          if (config.config_key.toUpperCase() === 'RESERVE_FUND_CAPACITY') {
            this.reserveFundCapacity = config.config_value
          }
        })
      }
    },
    initValues() {
      this.merchantNo = ''
      this.feeType = '1'
      this.feeRegion = 'BRA'
      this.chargebackFee = 0
      this.chargebackFeeCurrency = 'BRL'
      this.refundFee = 0
      this.refundFeeCurrency = 'BRL'
      this.taxFee = 0
      this.taxFeeCurrency = 'BRL'
      this.avsFee = 0
      this.avsFeeCurrency = 'BRL'
      this.settlementFee = 0
      this.settlementFeeCurrency = 'BRL'
      this.chargebackCoverage = 0
      this.chargebackCoverageCurrency = '%'
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.updateItem()
            }
          })
        }
      })
    },
    updateItem() {
      // 编辑config配置list
      let target = ''
      const configs = []

      if (this.feeType === '1') {
        target = this.feeRegion
        const sSettlementFee = { fee_name: 'Settlement Fee', config_key: 'SETTLEMENT_FEE', config_value: `~,~,${this.settlementFee},${this.settlementFeeCurrency}` }
        configs.push(sSettlementFee)
        const sChargebackCoverage = { fee_name: 'Chargeback Coverage', config_key: 'CHARGEBACK_COVERED', config_value: `~,~,${this.chargebackCoverage},${this.chargebackCoverageCurrency}` }
        configs.push(sChargebackCoverage)
      }
      if (this.feeType === '3') {
        target = this.feeCurrency
        if (this.reserveFundPeriod) {
          const reserveFundPeriodConfig = { fee_name: 'Reserve Fund Period', config_key: 'RESERVE_FUND_PERIOD', config_value: this.reserveFundPeriod }
          configs.push(reserveFundPeriodConfig)
        }
        if (this.reserveFundCapacity) {
          const reserveFundCapacityConfig = { fee_name: 'Reserve Fund Capacity', config_key: 'RESERVE_FUND_CAPACITY', config_value: this.reserveFundCapacity }
          configs.push(reserveFundCapacityConfig)
        }
      }
      if (configs.size < 1) {
        return
      }

      const obj = {
        merchant_no: this.merchantNo,
        target_type: this.feeType,
        target,
        config_list: configs,
      }

      saveMerchantFeeConfigs(
        obj,
        () => {
          this.showAlert('success', 'Updated!', 'Success')
          this.$emit('update:is-merchant-fee-config-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
      this.initValues()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
