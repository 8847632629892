import axios from '@axios'

export default {
  namespaced: true,
  state: {
    channels: [],
  },
  getters: {},
  mutations: {},
  actions: {
    // 获取渠道过滤信息
    fetchChannelFilters() {
      return new Promise((resolve, reject) => {
        axios.post('/channel/listChannelFilters')
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 获取渠道明细
    fetchChannels() {
      return new Promise((resolve, reject) => {
        axios.post('/channel/listAllSubChannelsDetail')
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 保存渠道明细
    saveChannel(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.put('/channel/save', item)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 获取渠道元素
    fetchChannelElements() {
      return new Promise((resolve, reject) => {
        axios.post('/channel/listChannelElements')
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 保存渠道元素
    saveChannelElement(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/channel/updateChannelElement?index=${item.index}&op=${item.op}`, item.data)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 获取商户数据
    fetchMerchants() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
    // 获取一个商户的APP数据
    fetchMerchantApps(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/merchant/app/list?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 获取多个商户的APP数据
    fetchMerchantsApps(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/app/listAppsByMerchants', merchantNo)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchChannelFees(ctx, cid) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/fee/list/${cid}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    updateChannelFee(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/channel/fee/update', params)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    deleteChannelFee(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`/channel/fee/${params.channel_id}/${params.index}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    toggleMonitoring(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/channel/toggle-monitor', params)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    backupChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/backup-channel?channel_id=${params.cid}&backup=${params.backup}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    switchChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/switch?source_channel=${params.source_channel}&target_channel=${params.target_channel}&merchants=${params.merchants}&apps=${params.apps}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 导出渠道费率
    exportChannelFees() {
      return new Promise((resolve, reject) => {
        axios.post('/channel/export-channel-fees', null, { responseType: 'blob' })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 渠道分组
    fetchChannelGroups() {
      return new Promise((resolve, reject) => {
        axios.post('/channel/group/list')
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 保存渠道分组
    createChannelGroup(ctx, group) {
      return new Promise((resolve, reject) => {
        axios.put('/channel/group/create', group)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 更新渠道分组
    updateChannelGroup(ctx, group) {
      return new Promise((resolve, reject) => {
        axios.post('/channel/group/update', group)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    removeChannelGroup(ctx, groupId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/channel/group/remove?group_id=${groupId}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 渠道分组权重
    fetchChannelGroupWeights(ctx, groupId) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/group/list-channel-weights?group_id=${groupId}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 创建渠道分组权重
    createChannelWeight(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.put('/channel/group/create-channel-weight', params)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 更新渠道分组权重
    updateChannelWeight(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.put('/channel/group/update-channel-weight', params)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 删除渠道分组权重
    removeChannelWeight(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`/channel/group/remove-channel-weight?group_id=${params.group_id}&cid=${params.cid}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 根据Region和Method获取渠道分组权重
    fetchChannelGroupByRegionAndMethod(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/group/list-by-region-method?region=${params.region}&method=${params.method}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 渠道上线
    channelOnline(ctx, cid) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/group/online?cid=${cid}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 渠道下线
    channelOffline(ctx, cid) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/group/offline?cid=${cid}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 商户渠道上线
    onlineMerchantChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/online-channel?cid=${params.cid}&merchant_no=${params.merchant_no}&app_id=${params.app_id}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 商户渠道下线
    offlineMerchantChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/channel/offline-channel?cid=${params.cid}&merchant_no=${params.merchant_no}&app_id=${params.app_id}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    // 保存渠道组规则
    // saveGroupRouteRule(ctx, params) {
    //   return new Promise((resolve, reject) => {
    //     axios.put('/channel/group/rule/save', params)
    //       .then(response => { resolve(response) })
    //       .catch(error => { reject(error) })
    //   })
    // },
  },
}
